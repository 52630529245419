/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// require("./bootstrap");

import Vue from "vue";
window.Vue = Vue;

//LIBRARYS IMPORT
import { BootstrapVue } from "bootstrap-vue";
import VueSweetalert2 from "vue-sweetalert2";
import vClickOutside from "v-click-outside";
import VueApexCharts from "vue-apexcharts";
import VueMask from "v-mask";
import Vuelidate from "vuelidate";
import * as VueGoogleMaps from "vue2-google-maps";
import i18n from "./i18n";
import VuePaginate from 'vue-paginate';
import store from './store/index';
import  router  from './routes' //se importan las rutas
import "./views";
import Layouts from "./mixins/layouts.mixin";
import VueTour from 'vue-tour'
import auth from './middleware/auth';
require('vue-tour/dist/vue-tour.css')

Vue.prototype.$isDev = process.env.MIX_APP_ENV !== "production";
Vue.config.devtools = Vue.prototype.$isDev;
Vue.config.debug = Vue.prototype.$isDev;
Vue.config.silent = !Vue.prototype.$isDev;
import tinymce from "vue-tinymce-editor";

import 'vue2-toast/lib/toast.css';
import Toast from 'vue2-toast';
Vue.use(Toast, {
    type: 'success',
    duration: 3000,
    wordWrap: true,
    width: '150px'
});
// DECLARACION PARA QUE EL STORE ESTE DISPONIBLE EN TODA LA APP
Vue.prototype.$store = store
Vue.use(VueTour);
Vue.use(VuePaginate);
Vue.use(BootstrapVue);
Vue.use(vClickOutside);
Vue.use(VueMask);
Vue.use(Vuelidate);
Vue.use(require("vue-chartist"));
Vue.use(VueSweetalert2);
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
        libraries: "places"
    },
    installComponents: true
});
Vue.component("apexchart", VueApexCharts);
Vue.component("tinymce", tinymce);
Vue.component(
    "dynamic-component",
    require("./components/dynamic-component").default
);
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */





function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({...context, next: nextMiddleware });
    };
}

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ?
            to.meta.middleware : [to.meta.middleware];

        const context = {
            from,
            next,
            router,
            to,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0]({...context, next: nextMiddleware });
    }

    return next();
});
Vue.directive('format-number', {
    bind(el, binding, vnode) {
      el.addEventListener('input', () => {
        let value = el.value.replace(/[^\d.]/g, ''); // Eliminar cualquier cosa que no sea número o punto
        el.value = Number(value).toLocaleString('en-US'); // Añadir comas de miles
  
        // Actualizar el valor real en el v-model (si se está usando)
        if (vnode.componentInstance) {
          vnode.componentInstance.$emit('input', value);
        }
      });
  
      el.addEventListener('blur', () => {
        // En el blur aseguramos que el valor real sea actualizado correctamente
        let value = el.value.replace(/[^\d.]/g, '');
        el.value = Number(value).toLocaleString('en-US');
      });
    }
  });
  
//SE ENVIAN LOS PARAMETROS A LA APP

const app = new Vue({

    el: "#app",
    store,
    mixins: [Layouts],
    i18n,
    router,
    data: {
        sessionData: window.sessionData, // Asigna los datos de sesión a una propiedad de la instancia de Vue
       
        env: {
          URL_HUMA: 'http://localhost:8080',
        }
      }
});