export const menuItems = [{
        id: 1,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 2,
        label: "Presupuestos",
        icon: "bx-home-circle",

        subItems: [
            // {
            //     id: 3,
            //     label: "Centros de Costo",
            //     link: "/cost-centers-index",
            //     parentId: 2
            // },
            // {
            //     id: 4,
            //     label: "Insumos",
            //     link: "/supplies-index",
            //     parentId: 2
            // },
            // {
            //     id: 5,
            //     label: "Mano de Obra",
            //     link: "/work_force-index",
            //     parentId: 2
            // },
            // {
            //     id: 6,
            //     label: "Proveedores",
            //     link: "/providers-index",
            //     parentId: 2
            // },
            // {
            //     id: 7,
            //     label: "Partidas",
            //     link: "/construction_games-index",
            //     parentId: 2
            // },
            {
                id: 8,
                label: "Tarjetas de Precios Unitarios",
                link: "/unit_price_cards-index",
                parentId: 2
            },
            // {
            //     id: 9,
            //     label: "FSR calculadora",
            //     link: "/fsr-index",
            //     parentId: 2
            // },

            {
                id: 10,
                label: "Presupuesto Base",
                link: "/budget-index",
                parentId: 2
            },
            {
                id: 11,
                label: "Presupuesto de Control",
                link: "/explosion-index",
                parentId: 2
            },

            {
                id: 12,
                label: "Requisiciones",
                link: "/requisitions",
                parentId: 2
            },
            {
                id: 13,
                label: "Asignaciones",
                link: "/assignments",
                parentId: 2
            },



        ]
    },
    // {
    //     id: 12,
    //     label: "Catalogos",
    //     icon: "bx-home-circle",

    //     subItems: [{
    //             id: 13,
    //             label: "Mis Catalogos",
    //             link: "/catalogs-index",
    //             parentId: 12
    //         },



    //     ]
    // },
    {
        id: 14,
        label: "DOCUMENTOS",
        icon: "bx-home-circle",

        subItems: [
            {
                id: 15,
                label: "Archivo Guardavalores",
                link: "/docuemnts",
                parentId: 14
            },
           



        ]
    },
     {
        id: 16,
        label: "HUMA V1",
        icon: "bx bx-left-arrow-alt",
        link: "/"
    },

  

];

export function modificarMenu(nuevoItem) {
    const posicion = menuItems.findIndex(item => item.id === 16);

    menuItems[posicion]= nuevoItem;
  }