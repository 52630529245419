<script>
/**
 * Login component
 */
export default {
  data() {
    return {
      email: "alan.avila@grupoconstructorhuma.com",
      password: "123456789",
      tryingToLogIn: false,
      isAuthError: false,
    };
  },
  components: {},
  methods: {},

  props: {
    submitUrl: {
      type: String,
      required: true,
    },
    authError: {
      type: String,
      required: false,
      default: () => null,
    },
  },
  mounted() {
    document.body.classList.add("auth-body-bg");
    this.isAuthError = !!this.authError;
  },
};
</script>






<template>
  <div>
    <div class="container-fluid p-0">
      
      <div class="row g-0">
        <div class="col-xl-9">
          <div class="auth-full-bg pt-lg-5 p-4">
            <div class="w-100">
              <div class="bg-overlay"></div>
              <div class="d-flex h-100 flex-column">
                <div class="p-4 mt-auto">
                  <div class="row justify-content-center">
                    
                    <div class="col-lg-7">
                      <div class="text-center">
                        <h4 class="mb-3">
                          <i
                            class="
                              bx
                              bxs-quote-alt-left
                              text-primary
                              h1
                              align-middle
                              mr-3
                            "
                          ></i
                          ><span class="text-primary">HUMA</span> DESARROLLOS
                        </h4>

                        <div dir="ltr" class="owl-theme">
                          <b-carousel id="carousel-1" :interval="4000">
                            <b-carousel-slide>
                              <p class="font-size-16 mb-4">
                                " UNA NUEVA FORMA DE CONSTRUIR"
                              </p>
                              <div>
                                <h4 class="font-size-16 text-primary">
                                  since 2018
                                </h4>
                                <p class="font-size-14 mb-0">- HUMA SYSTEM 2.0.0</p>
                              </div>
                            </b-carousel-slide>

                            <b-carousel-slide>
                              <p class="font-size-16 mb-4">
                                " DIGITALIZANDO TU EMPRESA"
                              </p>
                              <div>
                                <h4 class="font-size-16 text-primary">
                                  Powered by VUE.JS
                                </h4>
                                <p class="font-size-14 mb-0">- HUMA SYSTEM 2.0.0</p>
                              </div>
                            </b-carousel-slide>
                          </b-carousel>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end col -->

        <div class="col-xl-3">
          <div class="auth-full-page-content p-md-5 p-4">
            <div class="w-100">
              <div class="d-flex flex-column h-100">
                <div class="mb-4 mb-md-5">
                  <a href="/" class="d-block auth-logo">
                    <!-- <img
                      src="/images/logo-dark.png"
                      alt=""
                      height="18"
                      class="auth-logo-dark"
                    />
                    <img
                      src="/images/logo-light.png"
                      alt=""
                      height="18"
                      class="auth-logo-light"
                    /> -->
                  </a>
                </div>
                <div class="my-auto">
                  <div>
                    <h5 class="text-primary">Bienvenido de nuevo!</h5>
                    <p class="text-muted">por favor ingresa tus datos para continuar.</p>
                  </div>

                  <div class="mt-4">
                    <b-alert
                      v-model="isAuthError"
                      variant="danger"
                      class="mt-3"
                      dismissible
                      >{{ authError }}</b-alert
                    >
                    <b-form class="p-2" :action="submitUrl" method="POST">
                      <slot />
                      <b-form-group
                        id="input-group-1"
                        label="Correo Electronico"
                        label-for="input-1"
                        class="mb-3"
                      >
                        <b-form-input
                          id="input-1"
                          name="email"
                          v-model="email"
                          type="text"
                          placeholder="Enter email"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        id="input-group-2"
                        label="Contraseña"
                        label-for="input-2"
                        class="mb-3"
                      >
                        <b-form-input
                          id="input-2"
                          v-model="password"
                          name="password"
                          type="password"
                          placeholder="Enter password"
                        ></b-form-input>
                      </b-form-group>
                     
                      <div class="mt-3 d-grid">
                        <b-button
                          type="submit"
                          variant="primary"
                          class="btn-block"
                          >Entrar</b-button
                        >
                      </div>
                     
                      <div class="mt-4 text-center">
                        <a href="/reset/password" class="text-muted">
                          <i class="mdi mdi-lock mr-1"></i> Olvidaste tu contraseña?
                        </a>
                      </div>
                    </b-form>
                   
                  </div>
                </div>

                <div class="mt-4 mt-md-5 text-center">
                  <p class="mb-0">
                    ©
                    {{ new Date().getFullYear() }}; HUMA DESARROLLOS
                    <i class="mdi mdi-heart text-danger"></i> by HUMADESARROLLOS
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container-fluid -->
  </div>
</template>

<style lang="scss" scoped>
::v-deep .carousel-caption {
  position: relative !important;
  right: 0;
  bottom: 1.25rem;
  left: 0;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #495057;
  text-align: center;
}

::v-deep .carousel-indicators li {
  background-color: rgba(85, 110, 230, 0.25);
}
</style>