
<style lang="scss"></style>

<template>
  <div>
    <div  class="right-bar">
      <simplebar class="h-100">
        <div class="rightbar-title px-3 py-4">
          <a
            href="javascript:void(0);"
            class="right-bar-toggle float-end"
            @click="hide"
          >
            <i class="mdi mdi-close noti-icon"></i>
          </a>
          <h5 class="m-0">PANEL DE CONTROL</h5>
        </div>
        <div class="p-3">
          <router-link to="/social-reasons-index">RAZONES SOCIALES</router-link>

          <hr class="mt-1" />

          <!-- Width -->
          <h6 class="mt-3">UNIDADES DE NEGOCIO</h6>
          <hr class="mt-1" />
          <multiselect
            @select="triggerChangeUnit"
            select-label="Seleccione..."
            label="text"
            v-model="bussiness_unit_id"
            :options="bussiness_units"
          ></multiselect>
        </div>
        <!-- Settings -->
      </simplebar>
    </div>
    <b-modal v-model="showModal" hide-footer centered header-class="border-0">
      <div class="text-center mb-4">
        <div class="avatar-md mx-auto mb-4">
          <div class="avatar-title bg-light rounded-circle text-primary h1">
            <i class="mdi mdi-email-open"></i>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-xl-10">
            <h4 class="text-primary">Subscribe !</h4>
            <p class="text-muted font-size-14 mb-4">
              Usted esta cambiando de uniodad de negocio por favor espere un
              momento
            </p>

            <div class="input-group bg-light rounded">
              <input
                type="email"
                class="form-control bg-transparent border-0"
                placeholder="Enter Email address"
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
              />

              <button class="btn btn-primary" type="button" id="button-addon2">
                <i class="bx bxs-paper-plane"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Right bar overlay-->
    <div class="rightbar-overlay"></div>
  </div>
</template>

<script>
import simplebar from "simplebar-vue";
import axios from "axios";
import Multiselect from "vue-multiselect";
import { mapState, mapActions, mapMutations } from "vuex";

/**
 * Right sidebar component
 */
export default {
  components: { simplebar, Multiselect },
  data() {
    return {
      showModal: false,
     
      layout: this.$root.layout.type,
      width: this.$root.layout.width,
      bussiness_unit_id: "",
      bussiness_units: [],
    };
  },
  async mounted() {
    await this.loadBussinessUnit();
    console.log('this.session',this.session);
  

  },
  computed: { ...mapState("Explosion", ["session"]) },

  methods: {
    ...mapActions("Explosion", ["actiongetSession"]),
    ...mapMutations("Explosion", ["getSession"]),
    hide() {
      this.$parent.toggleRightSidebar();
    },
    // eslint-disable-next-line no-unused-vars
    handleRightBarClick(e, el) {
      
      this.$parent.hideRightSidebar();
    },
    // eslint-disable-next-line no-unused-vars
    middleware(event, el) {
      return !event.target.classList.contains("toggle-right");
    },
    
   
  

  
    async loadBussinessUnit() {
      axios.get("api/loadBussinessUnit").then((response) => {
        this.bussiness_units = response.data;

        this.bussiness_unit_id = this.bussiness_units.filter(bu => bu.value == this.session.entity)[0];
      });
    },

    changeUnit(bussiness_unit_id) {
      console.log('this.session',this.session);
      // return;
      axios
        .post("api/changeUnit/", {
        bussiness_unit_id:bussiness_unit_id,
        user:this.session.user.usuario,
        pr:this.session.profile
        })
        .then((response) => {
          this.showModal = true;
          console.log('this.response',response);

         
          console.log('this.session',this.session);

        })
        .catch((error) => {
          // error.response.status Check status code
        })
        .finally(() => {
         location.reload();
        });
    },
    triggerChangeUnit(obj) {
      this.changeUnit(obj.value);
    },
  },
};
</script>