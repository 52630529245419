import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    assignmentsInfo:{
        cost_center_id:[],
        description:'',
        contractor:[],
        concepts:[]
    }
  },
  mutations: {
    getInfoAssignment(state, data) {
        state.assignmentsInfo = data;
      },
    
  },
  actions: {
    actiongetInfoAssignment({ commit }, val) {
        console.log('val',val)
        commit('getInfoAssignment', val);
      },    
  },
  


};








